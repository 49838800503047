import React, { useState } from 'react';
import {useNavigate} from "react-router-dom";
import {Input, Button, Form} from 'antd';
import RequestResetPassword from "../components/RequestResetPassword";

function UserSubmission({ createUser, setCreateUser }) {
  const navigate = useNavigate();
  const navigateToSplash= () => {
    setCreateUser(false)
    navigate('/'); // Navigate to the main page
  };
  const [inputFirstNameText, setInputFirstNameText] = useState('');
  const [inputLastNameText, setInputLastNameText] = useState('');
  const [inputUsernameText, setInputUsernameText] = useState('');
  const [inputEmailText, setInputEmailText] = useState('');
  const [inputPasswordText, setInputPasswordText] = useState('');
  const [apiResponse, setApiResponse] = useState('');
  const [resetUser, setResetUser] = useState(false)

  // Function to handle text input change
  const handleFirstNameChange = (event) => {
    setInputFirstNameText(event.target.value);
  };
  const handleLastNameChange = (event) => {
    setInputLastNameText(event.target.value);
  };
  const handleUsernameChange = (event) => {
    setInputUsernameText(event.target.value);
  };
  const handleEmailChange = (event) => {
    setInputEmailText(event.target.value);
  };
  const handlePasswordChange = (event) => {
    setInputPasswordText(event.target.value);
  };

  const handleResetAccount = (event) => {
    setResetUser(true);
  };

  // Function to handle form submission
  const handleSubmit = async () => {

    try {

      // Make an API request using fetch (you can use axios if you prefer)
      // const response = await fetch('http://localhost:5000/user', {  // Works for Computer
      const apiUrl = process.env.REACT_APP_LOCALHOST_IP;
      const response = await fetch(`${apiUrl}:5000/user/register`, {  // Works for outside computer
        method: 'POST',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          first_name: inputFirstNameText,
          last_name: inputLastNameText,
          username: inputUsernameText,
          email: inputEmailText,
          password: inputPasswordText
        }),
      });

      if (response.ok) {
        const jsonResponse = await response.json();
        setApiResponse(`Success! Received response: ${JSON.stringify(jsonResponse)}`);
      } else {
        const jsonResponse = await response.json();
        const failure = jsonResponse.result.response;
        setApiResponse(`Error: ${failure}`);
      }
    } catch (error) {
      console.error('Error submitting text:', error);
      setApiResponse('An error occurred while submitting the text.');
    }
  };
  return (
    <div style={{ textAlign: 'center', marginTop: '30px' }}>
        {resetUser ?
          <RequestResetPassword
              resetUser={resetUser}
              setResetUser={setResetUser}
          />:
          <>
            <h2>Create User</h2>
            <p>Below please enter the information to create a user</p>
            <Form
                layout="vertical"  // Ensures vertical layout
                onFinish={handleSubmit}
                style={{ maxWidth: '300px', margin: '0 auto' }}  // Optional: center the form
            >
                <Form.Item label="First Name" name="firstName" rules={[{ required: true, message: 'Please input your First Name!' }]}>
                    <Input
                        value={inputFirstNameText}
                        onChange={handleFirstNameChange}
                        placeholder="Enter first name"
                        style={{ width: '300px', marginRight: '10px' }}
                      />
                </Form.Item>
                <Form.Item label="Last Name" name="lastName" rules={[{ required: true, message: 'Please input your Last Name!' }]}>
                    <Input
                        value={inputLastNameText}
                        onChange={handleLastNameChange}
                        placeholder="Enter first name"
                        style={{ width: '300px', marginRight: '10px' }}
                      />
                </Form.Item>
                <Form.Item label="Username" name="username" rules={[{ required: true, message: 'Please input your Username!' }]}>
                    <Input
                        value={inputUsernameText}
                        onChange={handleUsernameChange}
                        placeholder="Enter first name"
                        style={{ width: '300px', marginRight: '10px' }}
                      />
                </Form.Item>
                <Form.Item label="email" name="email" rules={[{ required: true, message: 'Please input your Email!' }]}>
                    <Input
                        value={inputEmailText}
                        onChange={handleEmailChange}
                        placeholder="Enter first name"
                        style={{ width: '300px', marginRight: '10px' }}
                      />
                </Form.Item>
                <Form.Item label="password" name="password" rules={[{ required: true, message: 'Please input your Password!' }]}>
                    <Input.Password
                        value={inputPasswordText}
                        onChange={handlePasswordChange}
                        placeholder="Enter password"
                        style={{ width: '300px', marginRight: '10px' }}
                      />
                </Form.Item>
                <Form.Item>
                    <Button type="primary" htmlType="submit" style={{ marginTop: '10px', padding: '10px 20px' }}>
                        Submit
                    </Button>
                </Form.Item>
            </Form>
          </>
        }
      <Button type="primary" onClick={navigateToSplash} style={styles.button}>
        Home
      </Button>
      {!resetUser && <Button
          type="primary"
          onClick={handleResetAccount}
      >Reset Password</Button>}
      <div style={{ marginTop: '20px' }}>
        <h3>API Response:</h3>
        <pre>{apiResponse}</pre>
      </div>
    </div>
  );
}

const styles = {
  button: {
    padding: '10px 20px',
    fontSize: '16px',
    cursor: 'pointer',
    backgroundColor: '#007bff',
    color: '#fff',
    border: 'none',
    borderRadius: '5px',
    marginTop: '20px',
  },
};

export default UserSubmission;
