import React, { useState } from 'react';
import {useNavigate} from "react-router-dom";
import { Divider, Input, Button } from 'antd';

function SubmitQuery() {
  const navigate = useNavigate();
  const navigateToSplash= () => {
    navigate('/'); // Navigate to the main page
  };
  const [inputQuestionText, setInputQuestionText] = useState('');
  const [apiResponse, setApiResponse] = useState({ request: '', response: ''});
  const handleQuestionChange = (event) => {
    setInputQuestionText(event.target.value);
  };

  // Function to handle form submission
  const handleSubmit = async (event) => {

    event.preventDefault(); // Prevents the page from reloading

    try {

      // Make an API request using fetch (you can use axios if you prefer)
      // const response = await fetch('http://localhost:5000/user', {  // Works for Computer
      const username = localStorage.getItem('username')
      const token = localStorage.getItem('token')
      const apiUrl = process.env.REACT_APP_LOCALHOST_IP;
      console.log(`Username: ${username}`)
      console.log(`Token: ${token}`)
      const url = `${apiUrl}:5000/inquire/ask`
      console.log(`URL: ${url}`)
      const response = await fetch(url, {
        method: 'POST',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`, // Include the Bearer token
        },
        body: JSON.stringify({
          username: username,
          question: inputQuestionText
        }),
      });

      if (response.ok) {
        const jsonResponse = await response.json();
        const queryResponse = jsonResponse.result.response
        setApiResponse({
            request: inputQuestionText,
            response: queryResponse
      });
      } else {
        const jsonResponse = await response.json();
        const failure = jsonResponse.result.response;
        setApiResponse({
            request: inputQuestionText,
            response: `Error: ${failure}`
        });
      }
    } catch (error) {
      console.error('Error submitting text:', error);
      setApiResponse({
          request: inputQuestionText,
          response: 'An error occurred while submitting the text'
      });
    }
  };
  return (
    <div style={{ textAlign: 'center', marginTop: '30px' }}>
      <h2>Ask A Question</h2>
      <p>Below please enter your username and then a question</p>
      <label htmlFor="Question">Question:</label>
      <Input
        value={inputQuestionText}
        onChange={handleQuestionChange}
        placeholder="Enter your question"
        style={styles}
      />
      <Button type="primary" onClick={handleSubmit} style={{ marginTop: '10px', padding: '10px 20px' }}>
        Ask
      </Button>
      <Button onClick={navigateToSplash} style={styles.button}>
        Home
      </Button>
      <div style={styles.container}>
        <h3>API Response:</h3>
        <pre>{apiResponse.request && (
            <>
              <Divider style={{  borderColor: '#7cb305' }} orientation="center">Your Question</Divider>
              <p style={styles.paragraph}>{apiResponse.request.replace(/_/g, ' ').charAt(0).toUpperCase() + apiResponse.request.replace(/_/g, ' ').slice(1).toLowerCase()}</p>

              <Divider style={{  borderColor: '#7cb305' }} orientation="center">The Response</Divider>
              <p style={styles.p}>{apiResponse.response.replace(/\\n/g, '\n')}</p>
            </>
          )}
        </pre>
      </div>
    </div>
  );
}

const styles = {
  container: {
    marginTop: '20px',
    padding: '20px',
    maxWidth: '90%', // Ensures the container doesn’t stretch beyond the screen width
    margin: '0 auto', // Centers the container
  },
  button: {
    padding: '10px 20px',
    fontSize: '16px',
    cursor: 'pointer',
    backgroundColor: '#007bff',
    color: '#fff',
    border: 'none',
    borderRadius: '5px',
    marginTop: '20px',
  },
  paragraph: {
    wordWrap: 'break-word',  // Ensures long words are wrapped
    whiteSpace: 'normal',    // Allows text to wrap normally
    lineHeight: '1.6',       // Improves readability with a comfortable line height
  },
  p: {
    backgroundColor: '#f0f0f0',
    align: "center",
    padding: '10px',
    whiteSpace: 'pre-wrap',   // Allows wrapping
    wordWrap: 'break-word',   // Breaks long words
    // maxWidth: '300px',        // Set the max-width for wrapping
    border: '1px solid #ccc',
    borderRadius: '4px',
  }
};

export default SubmitQuery;
