import React, {useState} from 'react';
import { useNavigate } from 'react-router-dom';
import Users from "../components/Users";

function ManageUsersPage() {
  const [isTableVisible, setIsTableVisible] = useState(false);
  const navigate = useNavigate();
  const navigateToSplash= () => {
    navigate('/'); // Navigate to the main page
  };
  const navigateToUserSubmission= () => {
    navigate('/user_submission'); // Navigate to the main page
  };

  const handleHideUsers = () => {
    setIsTableVisible(false);
  }

  // Function to handle button click
  const handleButtonClick = () => {
    setIsTableVisible(true); // Show the table when the button is clicked
  };
  return (
    <div style={styles.container}>
      <h1>Manage Users</h1>
      <button onClick={navigateToUserSubmission} style={styles.button}>
        Create User
      </button>
      <button onClick={handleButtonClick} style={styles.button}>
        Get All Users
      </button>
      {isTableVisible && <button
          onClick={handleHideUsers} style={styles.button}>
        Hide Users
      </button>}
      <button onClick={navigateToSplash} style={styles.button}>
        Home
      </button>
      {isTableVisible && <Users />}
    </div>
  );
}

const styles = {
  container: {
    padding: '20px',
    textAlign: 'center',
  },
  button: {
    padding: '10px 20px',
    fontSize: '16px',
    cursor: 'pointer',
    backgroundColor: '#007bff',
    color: '#fff',
    border: 'none',
    borderRadius: '5px',
    marginTop: '20px',
  },
};

export default ManageUsersPage;
