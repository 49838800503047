// src/components/SplashPage.js
import React from 'react';
import { useNavigate } from 'react-router-dom';
import {Button} from "antd";

function SplashPage({ isAuthenticated, setIsAuthenticated }) {
  const navigate = useNavigate();

  const navigateToUserPage = () => {
    navigate('/user'); // Navigate to the main page
  };
  const navigateToInquiresPage = () => {
    navigate('/inquire'); // Navigate to the main page
  };

  const logoutApp = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('username')
    setIsAuthenticated(false);
    navigate('/');
  };

  return (
    <div style={styles.container}>
      <h1>Choco Learning App</h1>
      <p>Get ready to get answers to your questions.</p>
      <Button onClick={navigateToUserPage} style={styles.button}>
        Manage User
      </Button>
      <Button onClick={navigateToInquiresPage} style={styles.button}>
        Inquires
      </Button>
      <Button onClick={logoutApp} style={styles.button}>
        Logout
      </Button>
    </div>
  );
}

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100vh',
    backgroundColor: '#f0f0f0',
  },
  button: {
    padding: '10px 20px',
    fontSize: '16px',
    cursor: 'pointer',
    backgroundColor: '#007bff',
    color: '#fff',
    border: 'none',
    borderRadius: '5px',
    marginTop: '20px',
  },
};

export default SplashPage;
