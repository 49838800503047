import React, { useState } from 'react';
import { useParams } from 'react-router-dom';

function ResetPassword() {
  const { token } = useParams(); // Assuming React Router is being used
  const [password, setPassword] = useState('');
  const [message, setMessage] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log(`Token: ${token}`)
    try {
      const url = window.location.href;
      const tokenStr = url.split("?token=")[1];
      const apiUrl = process.env.REACT_APP_LOCALHOST_IP;
      const response = await fetch(`${apiUrl}:5000/user/reset-password`, {  // Works for outside computer
        method: 'POST',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          token: tokenStr,
          password: password
        }),
      });
      const jsonResponse = await response.json();
      if (response.ok) {
        setMessage(`Success! Received response: ${jsonResponse.result.response}`);
      } else {
        setMessage(`Error: ${jsonResponse.result.response}`);
      }
    } catch (error) {
      setMessage('Failed to reset password. Please try again.');
    }
  };

  return (
    <div>
      <h2>Reset Your Password</h2>
      <form onSubmit={handleSubmit}>
        <input
          type="password"
          placeholder="Enter your new password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          required
        />
        <button type="submit">Reset Password</button>
      </form>
      {message && <p>{message}</p>}
    </div>
  );
}

export default ResetPassword;
