// src/App.js
import React, { useEffect, useState } from 'react';
import { Table } from 'antd'; // Import Ant Design's Table component

function Users() {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);

  // Fetch data from the API
  useEffect(() => {
    const fetchData = async () => {
      try {
      const apiUrl = process.env.REACT_APP_LOCALHOST_IP;
      const response = await fetch(`${apiUrl}:5000/user/users`); // Example API endpoint
        const result = await response.json();
        const user_profiles = result.result.users
        // Transform data to match Ant Design's Table data format
        const formattedData = user_profiles.map(user => ({
          key: user.id, // Set the key property
          first_name: user.first_name.charAt(0).toUpperCase() + user.first_name.slice(1).toLowerCase(),
          last_name: user.last_name.charAt(0).toUpperCase() + user.last_name.slice(1).toLowerCase(),
          user_uuid: user.user_uuid,
          username: user.username.toLowerCase(),
          email: user.email.toLowerCase()
        }));
        setData(formattedData);
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  // Define the columns for the Ant Design Table
  const columns = [
    {
      title: 'First Name', // Column header
      dataIndex: 'first_name', // Field name from the data object
      key: 'first_name',
    },
    {
      title: 'Last Name',
      dataIndex: 'last_name',
      key: 'last_name',
    },
    {
      title: 'Username',
      dataIndex: 'username',
      key: 'username',
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
    },
  ];

  return (
    <div style={{ padding: 20 }}>
      <h2>App Users</h2>
      <Table
        columns={columns} // Pass the columns configuration
        dataSource={data} // Pass the fetched data
        loading={loading} // Display loading indicator while fetching data
        bordered
        pagination={{ pageSize: 5 }} // Optional: Set pagination size
        scroll={{ y: 300 }} // Enables vertical scrolling
        style={{ width: '100%' }} // Makes the table container responsive
      />
    </div>
  );
}

export default Users;

