import React, { useState } from 'react';
import {useNavigate} from "react-router-dom";

function RequestResetPassword({resetUser, setResetUser}) {
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');

  const navigateToSplash= () => {
    setResetUser(false)
    navigate('/'); // Navigate to the main page
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const apiUrl = process.env.REACT_APP_LOCALHOST_IP;
      const response = await fetch(`${apiUrl}:5000/user/request-password-reset`, {
        method: 'POST',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          email: email,
        }),
      });
      if (response.ok){
        const jsonResponse = await response.json();
        setMessage(jsonResponse.result.response);
        console.log('Email sent for using password');
        navigateToSplash()
      } else {
        const jsonResponse = await response.json();
        setMessage(jsonResponse.result.response);
      }

    } catch (error) {
      console.error("Error: ", error)
      setMessage('Failed to request password reset. Please try again.');
    }
  };

  return (
    <div>
      <h2>Request Password Reset</h2>
      <form onSubmit={handleSubmit}>
        <input
          type="email"
          placeholder="Enter your email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
        />
        <button type="submit">Request Reset</button>
      </form>
      {message && <p>{message}</p>}
    </div>
  );
}

export default RequestResetPassword;
