import './App.css';
import React, {useEffect, useState} from 'react';
import {BrowserRouter, Route, Routes} from 'react-router-dom';
import SplashPage from './pages/SplashPage';
import ManageInquiresPage from './pages/ManageInquiresPage';
import UserSubmission from './pages/UserSubmissionPage';
import ManageUsersPage from "./pages/ManageUserPage";
import SubmitQuery from "./pages/SubmitQueryPage";
import Login from "./components/Login";
import ResetPassword from "./components/ResetPassword";
import RequestResetPassword from "./components/RequestResetPassword";

function App() {
    const [isAuthenticated, setIsAuthenticated] = useState(!!localStorage.getItem('token'));
    const [createUser, setCreateUser] = useState(false);
    const [resetUser, setResetUser] = useState(false);
    const [isResetPage, setIsResetPage] = useState(false);

    useEffect(() => {
        const url = window.location.href;
        if (url.includes('request-password')) {
            setIsResetPage(true)
        }
    }, [])

    useEffect(() => {
        const token = localStorage.getItem('authToken');
        if (token) {
          setIsAuthenticated(true);
        }
      }, []);

    const handleLoginSuccess = () => {
        setIsAuthenticated(true); // Update authentication state
    };

    return (
        <div>
            <BrowserRouter>
              {isAuthenticated ?
                  <Routes>
                    <Route path="/" element={
                        <SplashPage
                            isAuthenticated={isAuthenticated}
                            setIsAuthenticated={setIsAuthenticated}
                        />} />
                    <Route path="/inquire" element={<ManageInquiresPage />} />
                    <Route path="/user" element={<ManageUsersPage />} />
                    <Route path="/request-password-reset" element={<RequestResetPassword />} />
                    <Route path="/user_submission" element={<UserSubmission
                        createUser={createUser}
                        setCreateUser={setCreateUser}
                    />} />
                    <Route path="/ask" element={<SubmitQuery />} />
                  </Routes>
                  :
                <>
                    <Routes>
                        <Route path="/request-password" element={<ResetPassword />} />
                        <Route path="/request-password-reset" element={<RequestResetPassword />} />
                    </Routes>
                    {!isResetPage && <Login
                        onLoginSuccess={handleLoginSuccess}
                        isAuthenticated={isAuthenticated}
                        setIsAuthenticated={setIsAuthenticated}
                    />}
                </>
              }
            </BrowserRouter>
        </div>
    );
}

export default App;
