import React, {useState} from 'react';
import { useNavigate } from 'react-router-dom';
import Queries from "../components/Queries";
import {Table} from "antd";

function ManageInquiresPage() {
  const navigate = useNavigate();
  const [isTableVisible, setIsTableVisible] = useState(false);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const navigateToSplash= () => {
    navigate('/'); // Navigate to the main page
  };

  // Function to handle button click
  const handleButtonClick = () => {
    setIsTableVisible(true); // Show the table when the button is clicked
  };

  const handleHideQueries = () => {
    setIsTableVisible(false);
  }

  const navigateToAskQuestion= () => {
    navigate('/ask'); // Navigate to the main page
  };

  const handleSubmit = async () => {
    setLoading(true);

    try {
      const username = localStorage.getItem('username')
      const apiUrl = process.env.REACT_APP_LOCALHOST_IP;
      const response = await fetch(`${apiUrl}:5000/inquire/user/${username}`); // Example API endpoint
      const result = await response.json();
      const queries = result.result.queries

      // Transform data to match Ant Design's Table data format
      const formattedData = queries.map(query => ({
        key: query.id, // Set the key property
        creation_time: query.creation_time,
        request: query.request.replace(/_/g, ' ').charAt(0).toUpperCase() + query.request.replace(/_/g, ' ').slice(1).toLowerCase(),
        request_id: query.request_id,
        user_id: query.user_id,
        response: query.response
      }));
        const reversedData = [...formattedData].reverse();
        setData(reversedData);
        handleButtonClick()
    } catch (error) {
      console.error('Error fetching data:', error);
      alert('Failed to fetch data. Please try again.');
    } finally {
      setLoading(false);
    }
  }

  const columns = [
    {
      title: 'Request',
      dataIndex: 'request',
      key: 'request',
    },
    {
      title: 'Response',
      dataIndex: 'response',
      key: 'response',
    }
  ];

  return (
    <div style={styles.container}>
      {!isTableVisible &&  <h1>Inquires</h1>}
      {!isTableVisible &&  <p>What would you like to do:</p>}
      <button onClick={navigateToAskQuestion} style={styles.button}>
        Ask Question
      </button>
      {!isTableVisible &&
          <button onClick={handleSubmit} style={styles.button}>
            Previous Questions
          </button>
      }
      {!isTableVisible &&
        <button onClick={navigateToSplash} style={styles.button}>
          Home
        </button>
      }
      {isTableVisible && <button
          onClick={handleHideQueries} style={styles.button}>
        Hide Queries
      </button>}
      {isTableVisible &&
        <Table
          columns={columns} // Pass the columns configuration
          dataSource={data} // Pass the fetched data
          loading={loading} // Display loading indicator while fetching data
          bordered
          pagination={{ pageSize: 5 }} // Optional: Set pagination size
          scroll={{ y: 300 }} // Enables vertical scrolling
          style={{ width: '100%' }} // Makes the table container responsive
        />}
    </div>
  );
}

const styles = {
  container: {
    padding: '20px',
    textAlign: 'center',
  },
  button: {
    padding: '10px 20px',
    fontSize: '16px',
    cursor: 'pointer',
    backgroundColor: '#007bff',
    color: '#fff',
    border: 'none',
    borderRadius: '5px',
    marginTop: '20px',
  },
};

export default ManageInquiresPage;
