import React, {useState} from 'react';
import {Button, Input} from "antd";
import UserSubmission from "../pages/UserSubmissionPage";

function Login({ onLoginSuccess, isAuthenticated, setIsAuthenticated }) {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [clicked, setClicked] = useState(false);
  const [createUser, setCreateUser] = useState(false)
  const [error, setError] = useState('');

  const handleCreateAccount = (event) => {
    setCreateUser(true);
  };

  const handleLogin = async (e) => {
    e.preventDefault();
    setClicked(true)
    try {
      const apiUrl = process.env.REACT_APP_LOCALHOST_IP;
      console.log(`URL: ${apiUrl}`)
      const response = await fetch(`${apiUrl}:5000/auth/login`, {  // Works for outside computer
        method: 'POST',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          username: username,
          password: password
        }),
      });
      if (response.ok) {
        const jsonResponse = await response.json();
        const token = jsonResponse.result.api_key;

        localStorage.setItem('token', token); // Save token to local storage
        localStorage.setItem('username', username); // Save token to local storage
        onLoginSuccess(); // Call the parent component's function to indicate login success
        setIsAuthenticated(true)
      } else {
        const jsonResponse = await response.json();
        const failure = jsonResponse.result.response;
        setError(`Invalid username or password: ${failure}`);
        console.error(`Error logging in:  ${failure}`);
        // window.location.reload()
      }

    } catch (error) {
      setIsAuthenticated(false)
      console.error('Login error:', error);
      setError('Invalid username or password');
    }
  };

  return (
    <div>
      {createUser ?
        <UserSubmission
              createUser={createUser}
              setCreateUser={setCreateUser}
          />:
          <>
            <h2>Login</h2>
            <Input
              placeholder="Username"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              required
            />
            <Input
              type="password"
              placeholder="Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
            <Button type="primary" onClick={handleLogin}>Login</Button>
            <Button type="primary" onClick={handleCreateAccount}>Create Account</Button>
            {error && <p style={{ color: 'red' }}>{error}</p>}
            {!error && clicked && <p style={{ color: 'green' }}>You are logged in</p>}
          </>

      }
    </div>
  );
}

export default Login;
